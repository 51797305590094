<template>
  <div class="bbb" id="overview">
    <van-notice-bar v-if="notice !== ''" scrollable>
      {{ notice }}
    </van-notice-bar>
    <van-guide :imgList="imgList" v-if="guideshow" @end="end" />
    <div id="Interrogation" class="time_slot">
      <div class="yangfang" >
        <img class="icon" src="@/assets/images/address.png" />

        <div class="yaowu_val" :style="PharmacyInfo.name ? 'color: blue; ' : 'color: blue; '">
          <p @click="goto_pharmacy" class="divider">
            {{ PharmacyInfo.name ? PharmacyInfo.name : near_pharmacy_list.length > 0 ? '选择最近的药店' : '暂未选择药店' }}
          </p>
          <p v-if="!PharmacyInfo.name && near_pharmacy_list.length > 0 " class="near-pharmacy-info">
            最近的药店距离您
             <span class="near-pharmacy-distance">
             {{ near_pharmacy_list[0].distance }}
             </span>米 
          </p>
        </div>
      </div>
      <div class="menu-wrap">
        <div class="one" @click="tz(3)">
          <p>视频问诊</p>
          <span>1对1视频问答</span>
          <div class="button">立即问诊</div>
          
        </div>
        <div class="two" @click="tz(1)">
          <p>图文问诊</p>
          <span>60s急速接诊，快速联系</span>
        </div>
        <div class="three" @click="tz(2)">
          <p>语音问诊</p>
          <span>在线语音接诊</span>
        </div>
      </div>
      <div class="shangciyongyao-wrap"  v-if=" Achives.length !== 0">
        <div class="title">
          <div class="left">就诊人档案</div>
        </div>
        <div
        class="achives card-wrap"
        style="height: auto"
       
      >
        <van-swipe :autoplay="0" :height="100" >
          <van-swipe-item
            v-for="(item, index) of Achives"
            @click="handleImagePreview(item.pres_info.UrlImgs)"
          >
            <div class="achives-list-item" >
              <div class="achives-list-item-con">
                <img :src="item.pres_info.UrlImgs[0]" class="image"  />
                <div class="info">
                  <span class="Achiveshospital_name">
                    {{ item.pres_info.hospital_name }}
                  </span>
                  <time class="time">
                    {{ item.pres_info.patient_disease.join(',') }}
                  </time>
                </div>
              </div>
              <div class="operate">
               
              </div>
            </div>
          </van-swipe-item>
        </van-swipe>
      </div>
        <!-- <div class="list">
          {{
            CommonlyUsedDrugs.map?.((el) =>
              el.name.length <= 5 ? el.name : el.name.slice(0, 5) + '...'
            ).join(' | ') || '无'
          }}
        </div> -->
      </div>
      <div class="guanggao-wrap" style="position: relative"> 
        <span style="position: absolute; bottom: 5px; right: 5px; color: #fff;font-size: 12px;">0.0.4</span>
      </div>
      <div class="shangciyongyao-wrap">
        <div class="title">
          <div class="left">上次用药</div>
          <div class="right" @click="gotoMessage">查看全部</div>
        </div>

        <div class="list">
          <div
            v-for="(item, index) of LastUsedDrugs"
            :key="item.id"
            class="item"
          >
            {{
              item.name.length <= 8 ? item.name : item.name.slice(0, 8) + '...'
            }}
          </div>

          <div v-if="LastUsedDrugs.length == 0" class="item">无</div>
          <div v-else class="btn" @click="goto_repres">去续方</div>
        </div>
      </div>
      <footer_ />
    </div>

  </div>
</template>
<script>
import footer_ from '../components/footer.vue';
import { Loading } from 'element-ui';
import module_function from '../utils/login';
import mixin from '@/utils/mixin';
import wx from '../assets/js/jweixin-1.6.0.js';
import util from '../assets/js/util.js';
import sha1 from 'js-sha1';
import base from './api/base.js';
import { ImagePreview } from 'vant-green';
import shuiyin from '../assets/image/shuiyin.png';
import { DataMatrixHighLevelEncoder } from '@zxing/library';
import { Dialog } from 'vant-green';
import method from '../assets/js/util.js'
export default {
  mixins: [mixin],
  components: {
    footer_,
  },
  data() {
    return {
      active: 0,
      imgList: [shuiyin],
      guideshow:false,
      stepsList: ['申请问诊', '查看问诊', '问诊沟通', '药店统筹'],
      btncolor: 'linear-gradient(to right, #02d3d9, #09e0c0)',
      LastUsedDrugs: [],

      Achives: [],
      diseaseList:[],


      latest_pres:{},
      CommonlyUsedDrugs: [],
      PharmacyInfo: {
        consultType: [],
      },
      consultType: ['', '图文问诊', '音频问诊', '视频问诊'],
      consultTypeIcon: ['', 'photo', 'phone', 'video'],
      date: [],
      near_pharmacy_list:[],
      notice: '',
      instance:null,
    };
  },
  methods: {
    handleImagePreview(item){
      this.guideshow = true
      this.instance  = ImagePreview(
        {
          images:item,
          asyncClose: true
        }
      );
    },
    end(){
      this.guideshow = false
      this.instance.close();
    },
    goto_pharmacy(){
      if(this.near_pharmacy_list.length > 0){
        this.$router.push({
          path: '/to_pharmacy'
        })
      }
    },
    goto_repres(){
      sessionStorage.setItem('renew_pres_id',this.latest_pres.id)
      this.$router.push({
        path: '/pres',
        query: {
          consultType: this.latest_pres.consultType*1,
        }
      });
    },
    gotoMessage(){
      this.$router.push({
        name: 'message',
        query:{
          type: '去续方'
        }
      });
    },
    StepsCli(index) {
      let that = this;
      if (this.active == 0 && this.active == index) {
        this.btncolor = '#20B2AA';
        setTimeout(function () {
          that.btncolor = 'linear-gradient(to right, #02d3d9, #09e0c0)';
          that.tz(that.PharmacyInfo.consultType[0]);
        }, 500);
      } else if (
        (this.active == 1 || this.active == 2) &&
        this.active == index
      ) {
        this.$router.push('/message');
      } else if (this.active == 3 && this.active == index) {
        this.$alert('请到药店进行统筹报销', '提示', {
          confirmButtonText: '确定',
          type: 'warning',
        });
      }
    },
    tz(item) {
      sessionStorage.removeItem('renew_pres_id')
      if (this.isTimepharmacy_id_time()) {
        this.iftime(item);
        return;
      }
      if(localStorage.getItem('userinfo') &&localStorage.getItem('userrole_id')){
        if (this.PharmacyInfo.id && Array.isArray(this.PharmacyInfo.consultType) && !this.PharmacyInfo.consultType.includes(item.toString())) {
          this.$message.error(`提示：${this.consultType[item]}，当前药店尚未开通！`);
          return;
        }
      }
      
      let that = this;
      if (localStorage.getItem('userinfo') &&localStorage.getItem('userrole_id')) {
        if (!sessionStorage.getItem('pharmacy_id')) {
          this.$alert('请扫描药店二维码后再次申请问诊!', '提示', {
            confirmButtonText: '确定',
            type: 'warning',
            callback: (action) => {
              that.wxcode(item);
            },
          });
        } else {
          this.tuPres(item);
        }
      } else {
        this.$router.push('/login');
      }
    },
    async pre_check(item) {
      const ihNameResponse = await this.$api.article.getihname();
      localStorage.setItem('ih_type', ihNameResponse.data.result.name);
      
      const userInfoResponse = await this.$api.article.postihuserinfo(ihNameResponse.data.result.name);
      if (userInfoResponse.data.code === '200') {
        const patientResponse = await this.$api.article.InternetHospitalGet(
          {
            search_db: 'Patient',
            ih_type: localStorage.getItem('ih_type'),
          },
          {
            user_id: JSON.parse(localStorage.getItem('user')).user_id,
          }
        );
        var d = new Date().getTime()
        let date = [method.formatDate(d).slice(0,10)+ ' 00:00:01',method.formatDate(d).slice(0,10)+' 23:59:59']
        this.$api.article.query({search_db:'Pres',query_type:'equal',start_time:this.date[0],end_time:this.date[1]},{query:{found_id:localStorage.getItem('userrole_id')}}).then(res => {
            let number = 0
           
            res.data.result.forEach(element => {
              // if(element.doctorCheckStatus == 0 || element.doctorCheckStatus == '1'){
              //   number +=1
              // }
              if(element.pay_state == '1' || element.pay_state == '2' || element.pay_state == '3'){
                number +=1
              }
            });
            console.log(number)
            if(number == 0){
              // this.msgbox(patientResponse.data.result.data.PresCheck)
            }
        })
      }
    },
    msgbox(item) {
      const dialogOptions = {
        confirmButtonColor: '#1557ff',
        
      };

      if (!item) {
        Dialog.confirm({
          title: '医保授权',
          message: '统筹处方需要进行医保授权，当前就诊人尚未获得医保授权，是否授权',
          confirmButtonText: '授权',
          cancelButtonText: '取消',
          ...dialogOptions
        }).then(() => {
          console.log('授权');
          this.$api.article.InternetHospitalGet(
            {
              search_db: 'AuthPresUrl',
              ih_type: localStorage.getItem('ih_type')
            },
            {
              user_id: JSON.parse(localStorage.getItem('user')).user_id
            }
          ).then((res) => {
            window.location.href = res.data.result.data.presUrl;
          });
        });
      } else {
        Dialog.confirm({
          title: '医保授权',
          message: '您的医保已经授权，现在去申请处方',
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          ...dialogOptions
        }).then(() => {
          console.log('授权');
          this.$router.push({ name: 'Pres', query: {} });
        });
      }
    },
    async tuPres(item) {
      // 显示加载中提示
      this.$toast.loading({
        message: '加载中...',
        forbidClick: true
      });
      
      this.$api.article.getihname().then((res) => {
        localStorage.setItem('ih_type', res.data.result.name);
        this.$api.article.postihuserinfo(res.data.result.name).then((res) => {
          // 关闭加载提示
          this.$toast.clear();
          if (res.data.code == '200') {
            this.$router.push({
              name: 'Pres',
              query: {
                consultType: item,
              },
            });
          }
        }).catch(() => {
          this.$toast.clear();
          this.$toast.fail('加载失败');
        });
      }).catch(() => {
        this.$toast.clear();
        this.$toast.fail('加载失败');
      });
    },
    objKeySort(arys) {
      //先用Object内置类的keys方法获取要排序对象的属性名数组，再利用Array的sort方法进行排序
      var newkey = Object.keys(arys).sort();
      var newObj = ''; //创建一个新的对象，用于存放排好序的键值对
      for (var i = 0; i < newkey.length; i++) {
        //遍历newkey数组
        newObj += [newkey[i]] + '=' + arys[newkey[i]] + '&';
      }
      return newObj.substring(0, newObj.length - 1);
    },
    async loadList() {
      if (sessionStorage.getItem('pharmacy_id')) {
        let params = {
          search_db: 'Patient',
          query_type: 'equal',
          start: '0',
          length: '2',
        };
        let data = {
          query: {
            user_account: JSON.parse(localStorage.getItem('user')).user_account,
          },
        };
        this.$api.article.query(params, data).then((res) => {
          res.data.result.forEach((element) => {
            this.$api.article
              .GetPresMedicine(element.patient_id)
              .then((res) => {
                res.data.result.forEach((element) => {
                  if (this.CommonlyUsedDrugs.length == 1) {
                    return;
                  }
                  this.CommonlyUsedDrugs.push(element);
                });
              });
          });
        });
        this.$api.article
          .query(
            {
              search_db: 'Pres',
              query_type: 'first',
            },
            {
              query: {
                found_id: localStorage.getItem('userrole_id'),
                pharmacy_id: sessionStorage.getItem('pharmacy_id'),
              },
            }
          )
          .then((res) => {
            if (
              res.data.result.medicine &&
              res.data.result.medicine !== 'None'
            ) {
              res.data.result.pres_body = JSON.parse(res.data.result.pres_body);
              if (res.data.result.pres_body.Drugs.length > 3) {
                res.data.result.pres_body.Drugs.length = 3;
              }
              this.latest_pres = res.data.result
              this.LastUsedDrugs = res.data.result.pres_body.Drugs;
            }
          });
      }



      this.near_pharmacy()
      var d = new Date().getTime();
      this.date = [
        util.formatDate(d).slice(0, 10) + ' 00:00:01',
        util.formatDate(d).slice(0, 10) + ' 23:59:59',
      ];
      let res = await this.$api.article.query(
        {
          search_db: 'Pres',
          query_type: 'first',
          start_time: this.date[0],
          end_time: this.date[1],
        },
        { query: { found_id: localStorage.getItem('userrole_id') } }
      );
      let item = res.data.result;
      if (!item.id) {
        this.active = 0;
      } else if (item.pay_state == '3') {
        this.active = 2;
      } else if (item.state == 'adopt') {
        this.active = 3;
      } else {
        this.active = 1;
      }
    },
    getAchives(){
      this.$api.article
      .query2(
        {
          search_db: 'Achives',
          query_type: 'equal',
        },
        {
          query: [
            {
              fild: {
                found_id: localStorage.getItem('userrole_id'),
                project_share: JSON.parse(
                  localStorage.getItem('PharmacyInfo')
                ).project_id,
                state: 'disable',
              },
              type: '=',
              condition: '&&',
            },
          ],
        }
      )
      .then((res) => {
        let Achives = res.data.result;
        let that = this;
        if (Achives.length !== 0) {
          this.apireturn(
            'Project',
            'id',
            JSON.parse(localStorage.getItem('PharmacyInfo'))
              .project_id
          ).then((res) => {
            let ProjectInfo = res.data.result;
            this.$confirm(
              `您在${ProjectInfo.title}诊所就诊过,存在${Achives.length}条就诊记录，是否授权尚云方使用`,
              '提示',
              {
                distinguishCancelAndClose: true,
                confirmButtonText: '授权',
                cancelButtonText: '取消',
              }
            )
              .then(() => {
                // 创建更新请求的数组
                const updatePromises = Achives.map((element) => {
                  let params = {
                    search_db: 'Achives',
                    query_type: 'equal',
                  };
                  let data = {
                    query: {
                      id: element.id,
                    },
                    update: {
                      state: 'enable',
                    },
                  };
                  return this.$api.article.update(params, data);
                });

                // 等待所有请求完成
                Promise.all(updatePromises)
                  .then(() => {
                    this.getAchives(that.patient.id, that.patient.name);
                  })
                  .catch((error) => {
                    console.error('更新失败', error);
                  });
              })
              .catch((action) => {
                this.getAchives(that.patient.id, that.patient.name);
              });
          });
        } else {
          this.getAchives(undefined, undefined, );
          this.getAchives(undefined, undefined, false);
        }
      });
    },
    getAchives(patient_id,name,project_share = true) {
      this.$api.article
        .query2(
          {
            search_db: 'Achives',
            query_type: 'equal',
          },
          {
            query: [
              {
                fild: {
                  found_id: localStorage.getItem('userrole_id'),
                  project_share: project_share ?  JSON.parse(
                    localStorage.getItem('PharmacyInfo')
                  ).project_id : '',
                  state: 'enable',
                },
                type: '=',
                condition: '&&',
              },
            ],
          }
        )
        .then((res) => {
          let Achives = res.data.result;
          Achives.forEach((element) => {
            if (element.pres_info == 'None') {
              return;
            }
            
            let UrlImgs = [];
            element.pres_info = JSON.parse(element.pres_info);
            element.pres_info.imgs.forEach((item) => {
              UrlImgs.push(
                `${base.baseUrl}img?static_path=Pres&file_name=${item}&token=${
                  JSON.parse(localStorage.getItem('userinfo')).token
                }&userrole_id=${localStorage.getItem('userrole_id')}`
              );
            });
            element.pres_info['UrlImgs'] = UrlImgs;
          });
          Achives = Achives.filter(element => element.types != 'auto' && element.project_share != '123');
          this.Achives = this.Achives.concat(Achives);
          let AchivesArr = [];
          this.diseaseList.forEach((element) => {
            this.Achives.forEach((item) => {
              if (
                item.pres_info.patient_disease
                  .join('')
                  .indexOf(element.title) !== -1
              ) {
                AchivesArr.push(element.title);
              }
            });
          });
          if (AchivesArr.length !== 0) {
            if (!sessionStorage.getItem('AchivesMessage')) {
              const h = this.$createElement;
              this.$msgbox({
                title: '提示',
                message: h('p', { style: 'text-align: center' }, [
                  h('span', null, `您选择的下列疾病已存在就诊档案,`),
                  h('br'),
                  h('span', { style: 'color: teal' }, AchivesArr.join(',')),
                  h('br'),
                  h('i', null, `可使用档案更方便`),
                ]),
                confirmButtonText: '确定',
                beforeClose: (action, instance, done) => {
                  done();
                  sessionStorage.setItem('AchivesMessage', '0');
                },
              });
            }
          }
        });


   
    },
    getPharmacy(id) {
      console.log(id);
      if (id == null) {
        return;
      }
      this.loading = this.$loading({
          lock: true, //lock的修改符--默认是false
          text: '加载中。。。', //显示在加载图标下方的加载文案
          color: 'rgba(255, 255, 255, 1)',
          spinner: 'el-icon-loading', //自定义加载图标类名
          background: 'rgba(0, 0, 0, 0.9)', //遮罩层颜色
          target: document.querySelector('#table'), //loadin覆盖的dom元素节点
        });
      this.$api.article
        .query(
          {
            search_db: 'Pharmacy',
            query_type: 'first',
          },
          {
            query: {
              id: id ? id : sessionStorage.getItem('pharmacy_id'),
            },
          }
        )
        .then((res) => {
          this.notice = res.data.result.notice;
          if (res.data.result.consultType.indexOf('[') !== -1) {
            res.data.result.consultType = JSON.parse(
              res.data.result.consultType
            );
          } else {
            res.data.result.consultType = [];
          }
          this.PharmacyInfo = res.data.result;
          localStorage.setItem('PharmacyInfo', JSON.stringify(res.data.result));
          this.setItemPharmacy_id(res.data.result.id);
          this.getAchives()
          this.pre_check()
          this.loading.close();
          
        });
        setTimeout(() => {
          this.loading.close();
        }, 2000);
    },
    info(id) {
      this.$router.push({
        name: 'info',
        query: {
          id,
        },
      });
    },
    isTimeExpired(time1, time2) {
      // 将日期对象转换为毫秒数
      var time2 = new Date();
      var timestamp1 = time1.getTime();
      var timestamp2 = time2.getTime();
      // var eightHoursInMilliseconds = 8 * 60 * 60 * 1000;
      // 如果时间1的时间戳大于时间2的时间戳，表示时间1已经超过了时间2
      return timestamp1 > timestamp2;
    },
    wxcode(item) {
      let that = this;
      that.$api.article.getticket().then((res) => {
        let fullUrl = window.location.href;
        let url = fullUrl.split('#')[0];
        let timestamp = new Date().getTime();
        let sortStr = that.objKeySort({
          jsapi_ticket: res.data.result.ticket,
          noncestr: `${timestamp}`,
          timestamp: Math.ceil(timestamp / 1000),
          url,
        });
        wx.config({
          // debug: true, // 开启调试模式,调用的所有api的返回值会在客户端alert出来，若要查看传入的参数，可以在pc端打开，参数信息会通过log打出，仅在pc端时才会打印。
          appId: 'wx674869ee215ee6e7', // 必填，公众号的唯一标识
          timestamp: Math.ceil(timestamp / 1000), // 必填，生成签名的时间戳
          nonceStr: `${timestamp}`, // 必填，生成签名的随机串
          signature: sha1(sortStr), // 必填，签名
          jsApiList: ['scanQRCode'], // 必填，需要使用的JS接口列表
        });
        wx.ready(function (res) {
          wx.scanQRCode({
            needResult: 1, // 默认为0，扫描结果由微信处理，1则直接返回扫描结果，
            scanType: ['qrCode'], // 可以指定扫二维码还是一维码，默认二者都有
            success: function (res) {
              var url = res.resultStr; // 当needResult 为 1 时，扫码返回的结果
              if (url.indexOf('pharmacy_id=') !== -1) {
                let pharmacy_id = url.split('pharmacy_id=')[1].split('&')[0];
                if (pharmacy_id.length == 36) {
                  sessionStorage.setItem('pharmacy_id', pharmacy_id);
                  var d = new Date().getTime();
                  sessionStorage.setItem(
                    'pharmacy_id_time',
                    util.formatDate(d)
                  );
                  this.getPharmacy(sessionStorage.getItem('pharmacy_id'));
                  that.tuPres(item);
                } else {
                  that.$alert('您的药店码不正确，请扫描正确的药店码', '提示', {
                    confirmButtonText: '确定',
                    type: 'warning',
                  });
                }
              } else if (url.indexOf('http://weixin.qq.com/q/') !== -1) {
                let wx_str = url.split('/');
                that.getPharmacywxInfo(wx_str[wx_str.length - 1]);
              } else {
                that.$alert('请扫描正确的药店码后，进行问诊', '提示', {
                  confirmButtonText: '确定',
                  type: 'warning',
                });
              }
            },
          });
        });
      });
    },
    getPharmacywxInfo(wxStr) {
      let that = this;
      this.$api.article
        .query2(
          {
            search_db: 'WXQR',
            query_type: 'first',
          },
          {
            query: [
              {
                fild: {
                  wx_qr: wxStr,
                },
                type: '=',
                condition: '&&',
              },
            ],
          }
        )
        .then((res) => {
          if (res.data.result.id) {
            this.isTimepharmacy_id_time(
              JSON.parse(res.data.result.info).pharmacy_id
            );
            this.getPharmacy(JSON.parse(res.data.result.info).pharmacy_id);
          } else {
            that.$alert('您的药店码不正确，请扫描正确的药店码', '提示', {
              confirmButtonText: '确定',
              type: 'warning',
            });
          }
        });
    },
    signature() {},
    isTimepharmacy_id_time() {
      var d = new Date().getTime();
      if (sessionStorage.getItem('pharmacy_id')) {
        console.log(
          util.getFutureDate(
            util.formatDate(d),
            sessionStorage.getItem('pharmacy_id_time'),
            60
          )
        );
        if (
          util.getFutureDate(
            util.formatDate(d),
            sessionStorage.getItem('pharmacy_id_time'),
            60
          )
        ) {
          sessionStorage.removeItem('pharmacy_id');
          sessionStorage.removeItem('pharmacy_id_time');
          localStorage.removeItem('pharmacy_id');
          localStorage.removeItem('PharmacyInfo');
        }
      }
      // else{
      //   if(localStorage.getItem('PharmacyInfo')){
      //     let PharmacyInfo = JSON.parse(localStorage.getItem('PharmacyInfo'))
      //     sessionStorage.setItem('pharmacy_id',PharmacyInfo.id)
      //     sessionStorage.setItem('pharmacy_id_time',util.formatDate(d))
      //   }
      // }
    },
    //点击问诊按钮执行
    async iftime(item) {
      let that = this;
      await this.isTimepharmacy_id_time();
      if (!localStorage.getItem('PharmacyInfo')) {
        this.$alert('请扫描药店二维码后再次申请问诊', '提示', {
          confirmButtonText: '确定',
          type: 'warning',
          callback: (action) => {
            that.wxcode(item);
          },
        });
      }
    },
    toPatient() {
      this.$router.push({
        name: 'Patient',
        query: {},
      });
    },
    async afterLogin() {
      await this.isTimepharmacy_id_time();
      if (true) {
        if (!this.$route.query.pharmacy_id) {
          let respharmacy = await this.$api.article.getpharmacy();
          if (respharmacy.data.result.pharmacy_id !== '') {
            await this.getPharmacy(respharmacy.data.result.pharmacy_id);
          } else {
            this.getPharmacy(sessionStorage.getItem('pharmacy_id'));
          }
        }
      } else {
        this.PharmacyInfo = JSON.parse(localStorage.getItem('PharmacyInfo'));
      }
      this.isTimepharmacy_id_time();
      if (sessionStorage.getItem('type') == 'achive') {
        this.toPatient();
      }
    },
    indexHandle() {
      if (localStorage.getItem('user') && localStorage.getItem('userrole_id')) {
        this.afterLogin();
        this.loadList();
      }else if(!sessionStorage.getItem('trylogin')){
        Dialog.confirm({
          title: '提示',
          message: '您当前暂未登陆，是否登陆？',
          confirmButtonText: '是',
          cancelButtonText: '否',
        }).then(() => {
          this.$router.push({ name: 'login', query: {} });
        });
      }
    },
    setItemPharmacy_id(id) {
      var d = new Date().getTime();
      sessionStorage.setItem('pharmacy_id', id);
      sessionStorage.setItem('pharmacy_id_time', util.formatDate(d));
    },
    near_pharmacy(){
      this.$api.article.near_pharmacy().then((res)=>{
        console.log(res.data.result)
        this.near_pharmacy_list = res.data.result
      })
    }
  },
  async created() {
    sessionStorage.setItem('index', true);
    this.isTimepharmacy_id_time();
    var d = new Date().getTime();
    // sessionStorage.removeItem('Drugs');
    // sessionStorage.removeItem('AchivesMessage');
    // sessionStorage.removeItem('Current');
    // sessionStorage.removeItem('disease');
    // sessionStorage.removeItem('patientId');
    // sessionStorage.removeItem('family');

    if (this.$route.query.pharmacy_id) {
      sessionStorage.setItem('pharmacy_id', this.$route.query.pharmacy_id);
      this.getPharmacy(sessionStorage.getItem('pharmacy_id'));
      sessionStorage.setItem('pharmacy_id_time', util.formatDate(d));
    }
    if (this.$route.query.wechat_code) {
      sessionStorage.setItem('wechat_code', this.$route.query.wechat_code);
    }
    if (this.$route.query.type && this.$route.query.type == 'achive') {
      sessionStorage.setItem('type', this.$route.query.type);
    }
    if (this.$route.query.auth_phone && this.$route.query.auth_code) {
      this.$message({
        message: '自动登录中',
        type: 'success',
      });
      await module_function.sms_login(
        this.$route.query.auth_phone,
        this.$route.query.auth_code,
        '/index',
        'usercreated'
      );
      let that = this;
      setTimeout(() => {
        that.indexHandle();
      }, 500);
    }
    if (this.$route.query.type && this.$route.query.type == 'wechat') {
      window.location.href = 'https://cloud.net-sun.com/manual/img/wechat.png';
    }
  },
  mounted() {
    this.indexHandle();
 
  },
};
</script>
<style lang="less" scoped>
.divider{
  border-bottom: 1px solid blue;
}
.near-pharmacy-info{
  color: #000;
  font-size: 12px;
  .near-pharmacy-distance{
    color: red;
  }
  .near-pharmacy-time{
    color: hsl(236, 90%, 44%);
  }
}
.steps-txt {
  border-bottom: 1px solid rgb(51, 136, 255);
}

.Steps {
  position: fixed;
  bottom: 50px;
  left: 0;
  right: 0;
}
.van-button--normal {
  font-size: 0.8rem !important;
}
.padding-4 {
  padding: 0 4.9%;
}
.padding-l-20 {
  padding-left: 20px;
}
.padding_r_0 {
  padding-right: 0 !important;
}
.padding_lr_10 {
  padding: 0 20px;
}
.color_20d8de {
  color: #20d8de;
}
.display {
  display: flex;
}
.jcsb {
  justify-content: space-between;
}
.aic {
  align-items: center;
}
.van-nav-bar {
  background: rgba(255, 255, 255, 0) !important;
}
.van-nav-bar__title {
  color: #fff !important;
  font-weight: 600 !important;
}
.title {
  font-size: 16px;
  color: #000;
  font-weight: 600;
}
.margin_top {
  margin-top: 0.9rem !important;
}
.bpd {
  background: #fff;
  padding: 10px;
  border-radius: 6px;
}
.header_img {
  width: 100%;
  height: auto;
  img {
    width: 100%;
    height: 10rem;
  }
  .yaowu {
    margin: 0 auto;
    display: flex;
    color: #999999;
    font-size: 14px;
    justify-content: space-between;
    .yaowu_title {
      color: #000;
      white-space: nowrap;
      text-wrap: nowrap;
    }
  }
  button {
    font-size: 1rem !important;
    height: 4rem;
  }
}

#overview .header_img {
  height: auto;
  .van-button--normal {
    padding: 0 0.1rem;
  }
}
.scyy {
  background: linear-gradient(to right, #fdd8d3, #fee4dd, #fff2e8) !important;
  height: 7.4rem;

  .scyy_btn {
    display: flex;
    // margin-left: 10px;
    // justify-content: space-between;
    .van-button--small {
      margin-right: 10px;
      height: 2rem;
    }
  }
}
.zxxx_nr {
  display: flex;
  .zxxx_txt {
    width: 60%;
    .zxxx_title {
      font-size: 15px;
      color: #000;
      font-weight: 600;
      line-height: 20px;
      margin-top: 10px;
    }
    p {
      font-size: 10px;
      line-height: 20px;
    }
    .zxxx_time {
      margin-top: 10px !important;
    }
  }
  .zxxx_img {
    width: 40%;
    height: 5rem;
    border-radius: 2px;
    overflow: hidden;
    img {
      width: 100%;
      height: 100%;
    }
  }
}
.justify-sb {
  justify-content: center;
}
</style>
<style lang="less" scoped>
.relative {
  position: relative;
  top: -20px;
  left: 4px;
}

#overview {
  background-color: #f6f6f6;
}

#Interrogation {
  height: calc(100% - 49px);
  width: 100%;
  margin: 0 auto;
  padding: 0.5rem 1rem;
  padding-bottom: 4rem;
  border-radius: 6px;
  overflow: auto;

  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100%;
    background: linear-gradient(63deg, #92b6ff 0%, #508afd 100%) no-repeat;
    background-image: url('~@/assets/images/page-bg-3.png');
    background-size: 100% 100%;

    // border-radius: 0 0 1.59rem 1.59rem;

    display: block;
    z-index: 0;
  }

  div {
    z-index: 1;
    position: relative;
  }
}

.yangfang {
  display: flex;
  align-items: center;
  font-weight: 600;
  font-size: 1rem;

  .icon {
    margin-right: 6px;
    width: 0.8rem;
    height: 1rem;
    color: #1557ff;
  }

  .yaowu_title {
    white-space: nowrap;
  }

  .yaowu_val {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    display: flex;
    width: 100% ;
    justify-content: space-between;
    align-items: center;
  }
}

.search-wrap {
  margin-top: 1rem;
  padding: 0 1rem;
  width: 100%;
  height: 2.09rem;
  background: #ffffff;
  border-radius: 1.05rem;

  display: flex;
  align-items: center;

  .icon {
    margin-right: 6px;
    width: 1rem;
    height: 1rem;
  }

  .input {
    width: 11.69rem;
    font-family: SourceHanSansCN;
    font-weight: 500;
    font-size: 0.8rem;
    color: #d3d3d3;
  }
}

.menu-wrap {
  margin-top: 1.2rem;
  display: grid;
  grid-template-areas:
    'a b b'
    'a c c';
  gap: 0.66rem 0.66rem;

  width: 100%;
  height: 13.31rem;

  .one {
    grid-area: a;

    padding: 2.38rem 0 0 1.34rem;

    background: url('~@/assets/images/home-menu-icon-1.png') no-repeat;
    background-size: cover;
    box-shadow: 0rem 0rem 1rem 0rem rgba(205, 205, 205, 0.19);
    border-radius: 0.63rem;

    p {
      font-family: SourceHanSansCN;
      font-weight: 500;
      font-size: 1.53rem;
      color: #ffffff;
      text-shadow: 0rem 0rem 0rem rgba(73, 114, 246, 0.48);
    }

    span {
      margin-top: 0.36rem;
      font-family: SourceHanSansCN;
      font-weight: 400;
      font-size: 0.75rem;
      color: #ffffff;
      display: inline-block;
    }

    .button {
      margin-top: 1.5rem;
      display: flex;
      align-items: center;
      justify-content: center;

      width: 5.41rem;
      height: 1.53rem;
      background: #f6f6f6;
      border-radius: 0.77rem;

      font-family: SourceHanSansCN;
      font-weight: 500;
      font-size: 0.75rem;
      color: #4871f6;
    }
  }
  .two {
    grid-area: b;

    padding: 1.47rem 0 0 1.19rem;

    background: url('~@/assets/images/home-menu-icon-2.png') no-repeat;
    background-size: cover;
    box-shadow: 0rem 0rem 1rem 0rem rgba(205, 205, 205, 0.19);
    border-radius: 0.63rem;

    p {
      font-family: SourceHanSansCN;
      font-weight: 500;
      font-size: 1rem;
      color: #ffffff;
    }

    span {
      display: inline-block;
      margin-top: 0.6rem;
      font-family: SourceHanSansCN;
      font-weight: 400;
      font-size: 0.75rem;
      color: #ffffff;
    }
  }
  .three {
    grid-area: c;

    padding: 1.47rem 0 0 1.19rem;

    background: url('~@/assets/images/home-menu-icon-3.png') no-repeat;
    background-size: cover;
    box-shadow: 0rem 0rem 1rem 0rem rgba(205, 205, 205, 0.19);
    border-radius: 0.63rem;

    p {
      font-family: SourceHanSansCN;
      font-weight: 500;
      font-size: 1rem;
      color: #ffffff;
    }

    span {
      display: inline-block;
      margin-top: 0.6rem;
      font-family: SourceHanSansCN;
      font-weight: 400;
      font-size: 0.75rem;
      color: #ffffff;
    }
  }
}

.changyongyaowu-wrap {
  margin-top: 0.69rem;
  padding: 0.66rem 0.97rem 0.78rem;

  // background: url('~@/assets/images/home-changyong.png') no-repeat;
  background-size: cover;
  box-shadow: 0rem 0rem 1rem 0rem rgba(205, 205, 205, 0.19);
  border-radius: 0.69rem;

  .subtitle {
    height: 0.94rem;
  }

  .list {
    margin-top: 0.56rem;
    font-family: Adobe Heiti Std;
    font-weight: normal;
    font-size: 0.75rem;
    color: #8d8d8d;
  }
}

.guanggao-wrap {
  margin-top: 0.78rem;
  height: 9.41rem;
  background: url('~@/assets/images/home-banner.png') no-repeat;
  background-size: cover;
  box-shadow: 0rem 0rem 1rem 0rem rgba(205, 205, 205, 0.19);
  border-radius: 0.31rem;
}

.shangciyongyao-wrap {
  margin-top: 0.53rem;
  padding: 0.81rem 1.41rem 1.22rem 0.81rem;
  background: #ffffff;
  box-shadow: 0rem 0rem 1rem 0rem rgba(205, 205, 205, 0.19);
  border-radius: 0.69rem;

  .title {
    display: flex;
    justify-content: space-between;
    align-items: center;

    .left {
      padding-left: 0.34rem;
      border-left: 0.3rem solid #1557ff;

      font-family: SourceHanSansCN;
      font-weight: 600;
      font-size: 0.94rem;
      color: #282828;
    }
    .right {
      font-family: SourceHanSansCN;
      font-weight: 400;
      font-size: 0.69rem;
      color: #1557ff;
      line-height: 1.31rem;
      opacity: 0.86;
    }
  }

  .list {
    margin-top: 0.81rem;

    column-gap: 0.69rem;
    row-gap: 0.69rem;
    display: flex;
    flex-wrap: wrap;

    .item {
      padding: 0 1.24rem;
      height: 2.59rem;
      background: #f6f8ff;
      border-radius: 0.34rem;

      display: flex;
      align-items: center;
      justify-content: center;

      font-family: SourceHanSansCN;
      font-weight: 500;
      font-size: 0.75rem;
      color: #7a8ca6;
    }

    .btn {
      margin: 1.31rem 7.66rem 0;
      min-width: 6.9rem;
      height: 1.87rem;
      background: #4670f6;
      border-radius: 0.31rem;

      font-family: SourceHanSansCN;
      font-weight: 400;
      font-size: 0.89rem;
      color: rgba(255, 255, 255, 0.86);

      line-height: 1.87rem;
      text-align: center;
    }
  }
}
.van-notice-bar {
  // position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 99;
}

.time_slot {
  min-height: calc(100vh - 44px);
}
</style>
<style lang="less" scoped>
 .achives {
    .achives-list-item {
      padding: 0.75rem 0.44rem;

      &-con {
        display: flex;
        align-items: center;
      }

      .image {
        width: 9.53rem;
        height: 5.25rem;
        display: block;
        border-radius: 6px;
      }

      .info {
        margin-left: 1.03rem;
        display: flex;
        flex-direction: column;
        justify-content: center;

        .Achiveshospital_name {
          font-family: SourceHanSansCN;
          font-weight: 400;
          font-size: 0.94rem;
          color: #3d3d3d;
        }

        .time {
          margin-top: 0.72rem;
          font-family: SourceHanSansCN;
          font-weight: 400;
          font-size: 0.81rem;
          color: #a5a5a5;
        }
      }
      .operate {
        margin-top: 1.22rem;
        display: flex;
        justify-content: flex-end; 

        .el-button--default{
          margin-left: 1.66rem;
          padding: 0;
          width: 6.71rem;
          height: 1.6rem;
          line-height: 1.6rem;
          border-radius: 0.31rem;
          font-weight: 500;
          font-size: 0.75rem;
        }

        .el-button--primary {
          margin-left: 1.66rem;
          padding: 0;
          background-color: #396cef;
          border-color: #396cef;

          width: 6.71rem;
          height: 1.6rem;
          line-height: 1.6rem;
          border-radius: 0.31rem;

          font-weight: 500;
          font-size: 0.75rem;
        }
      }
    }
  }
  .card-wrap {
  padding: 1.03rem 0.81rem;
  min-height: 5.25rem;
  border-radius: 0.69rem;

  & .label::after {
    content: '(必填)';
    margin-left: 0.63rem;
    margin-bottom: 0.15rem;
    font-family: SourceHanSansCN;
    font-weight: 500;
    font-size: 0.56rem;
    color: #67c23a;
    align-self: flex-end;
  }

  .title {
    font-family: SourceHanSansCN;
    font-weight: bold;
    font-size: 0.94rem;
    color: #282828;

    display: flex;
    align-items: center;

    &::before {
      content: '';
      margin-right: 0.38rem;

      width: 0.19rem;
      height: 0.81rem;
      background: #1557ff;
      border-radius: 0.09rem;
    }
  }

  .con {
    padding: 1rem 0.1rem 0 0.44rem;
  }
}
</style>
